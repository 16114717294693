
import React, { Component,  useState, useEffect  } from 'react';


import logo from './logo.svg';
import './App.css';
import {ThreeDots } from "react-loading-icons"

const server="https://payments.fhbrundle.co.uk"

let d=Object.fromEntries(new URLSearchParams(window.location.search));

async function digestMessage(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message.toUpperCase());
  const hash = await crypto.subtle.digest('SHA-256', data);
  return "VERIFIED_"+new Uint8Array(hash).reduce((a, b) => a + b.toString(16).padStart(2, '0'), '').toUpperCase();
}

export default class App extends Component {
  constructor(props) {
    super(props);
    console.log(d)
    let mode=window.location.pathname
    if(d.ref!=undefined && d.amount!=undefined && d.name!=undefined){
      mode="payment_confirm"
    }

    //We can have another method for having a unique reference that
    this.state = {
          loading:true,
          mode:mode,
          debug:window.location.pathname,
          payment_value:d.amount||"",
          payment_ref:d.ref||"",
          payment_name:d.name||"",
          payment_notes:d.note||"",
          server_verified:"pending",
          response_data:Object.fromEntries(new URLSearchParams(window.location.search)),
          appurl:""
    };
  }

  async componentDidMount(){





if(window.location.pathname=="/success"){
  //its a response back from worldpay
  let data=Object.fromEntries(new URLSearchParams(window.location.search));
  if(data.mac2){
    console.log(data.mac2)
    const verification=await digestMessage(data.mac2)
    console.log(verification)
    if(window.validation==verification){
      this.setState({server_verified:true})
    }
  }


}
if (window.validation === "REDIRECTAPP") {
  let status = encodeURIComponent(window.location.pathname.replace("/", ""));
  // Extract and encode query parameters
  let params = new URLSearchParams(window.location.search.slice(1));
  let encodedParams = Array.from(params.entries()).map(([key, value]) => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }).join('&');
  this.setState({appurl: `fhbapp://payment?status=${status}&${encodedParams}`})
  window.location.href = `fhbapp://payment?status=${status}&${encodedParams}`;
  window.close()
}


  }

  

iosClick=(url)=>{
  window.location.href = url;
  window.close()
}

  request_payment_url=async()=>{
    if(parseFloat(this.state.payment_value)<1 || this.state.payment_value==""){
      alert("Payment value not valid!")
      return
    }
    if(this.state.payment_name.length==0){
      alert("Please provide a payment name")
      return
    }

    if(this.state.payment_ref.length==0){
      alert("Please provide a payment reference")
      return
    }

    this.setState({mode:"payment_frame"})
    this.setState({loading:true})




    let form_data=new FormData()
    form_data.append("payment_name",this.state.payment_name)
    form_data.append("payment_value",this.state.payment_value)
    form_data.append("payment_ref",this.state.payment_ref)
    form_data.append("payment_notes",this.state.payment_notes)
    let response = await fetch(server+'/api?cmd=payment_url', {
        method: 'POST',
        body: form_data
    });
    let data = await response.json();


    if(data.URL){
      window.location=data.URL
      this.setState({mode:""})
      return
      //this.setState({iframe:data.URL})

    }
    else{
      alert("Something went wrong")
      this.setState({mode:""})
    }




    this.setState({loading:false})




  }


  handlechange=(e)=>{
    let v={}
    v[e.target.name]=e.target.value
    this.setState(v)
  }




  render(){
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>


      {this.state.appurl.length!=0 &&(<>
          <a className="payment_link" onClick={()=>this.iosClick(this.state.appurl)}><div>Return</div></a>
      </>)}


      <div className="payment_container">
        {this.state.mode==""||this.state.mode=="/" ?(<a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Make a payment</div></a>):("")}

        {this.state.mode=="new_payment" ? (
          <React.Fragment>
            <div><input name="payment_name" value={this.state.payment_name} className="form_input" type="text"   onChange={this.handlechange}  placeholder="Name/Company"/></div>
            <div><input name="payment_value" value={this.state.payment_value} className="form_input" type="number"   onChange={this.handlechange}  placeholder="Ammount in £"/></div>
            <div><input name="payment_ref" value={this.state.payment_ref} className="form_input" type="text" onChange={this.handlechange}  placeholder="Payment reference" /></div>
            <div><textarea name="payment_notes" value={this.state.payment_notes} className="form_input" type="text"  onChange={this.handlechange}  placeholder="Payment notes" /></div>
            <div>
              <a className="payment_link" onClick={()=>this.setState({mode:"payment_confirm"})}><div>Next</div></a>
              <a className="payment_link" onClick={()=>this.setState({mode:"payment_confirm"})}><div>Back</div></a>
              </div>
          </React.Fragment>
      ):("")}

      {this.state.mode=="payment_confirm" ? (
        <React.Fragment>
          <div className="pad10"><b>{this.state.payment_name}</b> to pay F.H.Brundle <b>£{parseFloat(this.state.payment_value).toFixed(2)}</b></div>
          <div className="pad10">Payment reference:<b>{this.state.payment_ref}</b></div>
          <div className="pad10">Payment notes:<b>{this.state.payment_notes}</b></div>


          <a className="payment_link" onClick={()=>this.request_payment_url()}><div>Proceed</div></a>

          <a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Back</div></a>

        </React.Fragment>
    ):("")}


    {this.state.mode=="/success" ?(
        <React.Fragment>
          <div><b>Payment successful {this.state.server_verified==true ? ("✓"):("")}</b></div>
            Amount paid: £{(parseFloat(this.state.response_data.paymentAmount)/100).toFixed(2)}

            <a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Make another payment</div></a>
      </React.Fragment>
    ):("")}

    {this.state.mode=="/cancel" ?(
        <React.Fragment>
          <div><b>The payment was cancelled </b></div>

          <a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Make another payment</div></a>

      </React.Fragment>
    ):("")}

    {this.state.mode=="/failure" ?(
        <React.Fragment>
          <div><b>The payment failed, please try another payment method</b></div>
          <a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Make another payment</div></a>
      </React.Fragment>
    ):("")}


    {this.state.mode=="/pending" ?(
        <React.Fragment>
          <div><b>The payment is pending {this.state.response_data.status}</b></div>
          <a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Make another payment</div></a>
      </React.Fragment>
    ):("")}


    {this.state.mode=="/error" ?(
        <React.Fragment>
          <div><b>Something went wrong </b></div>
          <a className="payment_link" onClick={()=>this.setState({mode:"new_payment"})}><div>Make another payment</div></a>
      </React.Fragment>
    ):("")}




      </div>


{this.state.mode=="payment_frame" ? (
      <div className="payment_container">

        {this.state.loading==true? (<center>
          <div className="progress_snippit">preparing payment page</div>
          <ThreeDots/></center>):(
        <iframe className="payment_frame" src={this.state.iframe} title="F.H.Brundle Payment" />)}
      </div>
):("")}



    <div id="footer">{this.state.debug} {JSON.stringify(d,null,"\t")}</div>
    </div>
  );

}
}
